@import "style/colors";
@import "style/animations";
@import "style/utils";

.root {
  @include Inset(0);

  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $White40;
  transform: translate3d(0, 0, 100px);
}

.show {
  animation: 0.3s ease-out forwards appearence;
}

.hide {
  animation: 0.3s ease-out forwards hiding;
}
