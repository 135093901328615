.root {
  & > div {
    width: 280px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
}

.content {
  font-size: 14px;
}

.logo {
  overflow: hidden;
  width: 140px;
  height: 48px;
  margin: 20px auto 0;
  background: url("/static/img/old-icons/logo2.svg") no-repeat center;
  background-size: contain;
}
