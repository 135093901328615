@import "style/colors";

span.swiper-styled-pagination-bullet {
  display: inline-block;
  width: 16px;
  height: 2px;
  border-radius: 0;
  background-color: $Gray43;
  opacity: 0.2;
  transition: opacity 0.4s ease-out;

  &_active {
    opacity: 1;
  }
}

div.swiper-slide {
  z-index: 1;
}
