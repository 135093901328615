@import "style/colors";

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  width: 250px;
  min-height: 255px;
  padding: 53px 35px 23px;
  border-radius: 10px;
  background-color: $White;
}

.content {
  color: $Black;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.icon {
  display: block;
  margin: 0 auto 11px;
}

.cross {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.buttonConfirm {
  width: 100%;
  margin-top: 33px;
  border: 1px solid $Black;
  border-radius: 0;
  background-color: $White;
  color: $Black;
  font-size: 10px;

  &:hover {
    background-color: $Black;
    color: $White;
  }
}

.buttonContainer {
  width: 180px;
  margin-right: auto;
  margin-left: auto;
}
