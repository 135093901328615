@import "style/colors";
@import "style/typo-mixins";

.root {
  @include Body2Med;

  min-width: 117px;
  height: 50px;
  border-radius: 25px;
  color: $White40;
  transition: transform 0.3s ease-out;

  &:active:not(:disabled) {
    transform: scale(0.9);
  }
}

.white {
  background-color: $White40;
  color: $Gray41;

  &:disabled {
    background-color: $Gray42;
    color: $Gray41;
  }
}

.darkGray {
  background-color: $Gray43;

  &:disabled {
    background-color: $Gray43;
    color: $Gray44;
  }
}

.gray {
  background-color: $Gray41;

  &:disabled {
    color: $Gray44;
  }
}

.hollow {
  border: 2px solid $White40;
  background-color: rgba(0, 0, 0, 0);
  color: $White40;

  &:disabled {
    border-color: $Gray45;
    color: $Gray45;
  }
}

.brightWhite {
  background-color: $White41;
  color: $Gray43;

  &:disabled {
    opacity: 0.5;
  }
}

.hollowGray {
  border: 1px solid $Gray43;
  background-color: rgba(0, 0, 0, 0);
  color: $Gray43;

  &:disabled {
    border-color: $Gray46;
    color: $Gray46;
  }
}

.hollowLightGray {
  border: 1px solid $Gray47;
  background-color: $White41;
  color: $Gray47;

  &:disabled {
    border-color: $Gray46;
    color: $Gray46;
  }
}
