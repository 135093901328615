.svg-icon {
  display: inline-block;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  image-rendering: optimize-contrast;

  &_shopping-cart-with-arrow {
    width: 16px;
    height: 16px;
    background-image: url("/static/img/old-icons/shopping-cart-with-arrow.svg"); //оплата и доставка
  }

  &_credit-card {
    width: 20px;
    height: 20px;
    background-image: url("/static/img/old-icons/credit-card.svg"); //оплата и доставка
  }

  &_arrow-medium {
    width: 17px;
    height: 13px;
    background-image: url("/static/img/old-icons/arrow-medium.svg"); //оплата и доставка
  }

  &_card-with-arrow-down {
    width: 14px;
    height: 11px;
    background-image: url("/static/img/old-icons/card-with-arrow-down.svg"); //оплата и доставка
  }

  &_dollar {
    position: relative;
    top: 4px;
    width: 14px;
    height: 14px;
    background-image: url("/static/img/old-icons/dollar.svg"); //оплата и доставка
  }

  &_logout {
    width: 8px;
    height: 8px;
    background-image: url("/static/img/old-icons/logout.svg"); //Profile
  }

  &_delete {
    width: 9px;
    height: 9px;
    background-image: url("/static/img/old-icons/cross.svg"); // CartItem MiniPopup
  }

  /* order */
  &_smile-and_heart {
    width: 62px;
    height: 51px;
    background-image: url("/static/img/old-icons/smile-and-heart.svg"); // OrderPopupBasic1 OrderPopupBasic2
  }

  &_letters {
    width: 56px;
    height: 56px;
    background-image: url("/static/img/old-icons/letters.svg"); // OrderPopupBasic3
  }

  &_tick {
    width: 32px;
    height: 28px;
    background-image: url("/static/img/old-icons/tick.svg"); // OrderPopupBasic4
  }

  &_koromyslo {
    width: 35px;
    height: 32px;
    background-image: url("/static/img/old-icons/koromyslo.svg"); // FaqPartition  OrderPopupSuggestion1
  }

  &_pig {
    width: 31px;
    height: 33px;
    background-image: url("/static/img/old-icons/pig.svg"); // OrderPopupSuggestion2
  }

  &_shopping-bag-with-heart {
    width: 50px;
    height: 50px;
    background-image: url("/static/img/old-icons/shopping-bag-with-heart.png"); // старый лэйаут
  }

  &_back {
    width: 8px;
    height: 16px;
    background-image: url("/static/img/old-icons/arrow-back.svg"); // header
  }
}
