.logo {
  width: 117px;
  height: 18px;
}

.maskElem {
  animation: mask 1.5s infinite linear;
}

@keyframes mask {
  0% {
    x: -234px;
  }

  100% {
    x: 0;
  }
}
