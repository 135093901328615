@import "style/colors";
@import "style/typo-mixins";
@import "style/utils";
@import "style/animations";

.root {
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px 20px;
  background-color: $White41;
  color: $Gray43;
  cursor: pointer;
  animation: fade-in 0.3s ease, slide-in 0.3s ease,
    fade-out 0.3s ease 3s forwards;
}

.textContainer {
  @include TBold10;

  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;

  &:nth-child(2) {
    margin-left: 16px;
  }
}

.text {
  @include TBold8;

  color: $Gray42;
}

@keyframes slide-in {
  from {
    transform: translateY(10px);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}
