@import "style/colors";
@import "style/typo-mixins";

.root {
  & > div {
    width: auto;
    max-width: 450px;
    margin-left: 16px;
    margin-right: 16px;
    padding: 0;
    justify-content: stretch;
    border-radius: 0;
    background-color: $White40;
  }
}

.imageContainer {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
}
.imageFitter {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body {
  padding: 8px;
  text-align: left;
}

.content {
  padding: 8px;
  margin-bottom: 16px;
  @include TBold12;
  color: $Gray43;
}
.title {
  @include TBold16;
  color: $Black40;
  margin-bottom: 16px;
}

.checkboxField > span:last-child {
  @include TMed10;
  color: $Gray45 !important;;
}
.checkboxField > input + span {
  background-color: transparent !important;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  opacity: .3;
}

.button {
  height: 44px;
  padding: 0 24px;
  border-radius: 0;
  background-color: $White41;
  border: 1px solid $Gray42;
  @include THeavy10;
  color: $Gray43;
}

.bottomButton {
  width: 100%;
  height: 50px !important;
  margin-top: 16px;
}

.emailBlock {
  display: flex;
  margin-bottom: 16px;
  padding: 0 8px;
}
.email {
  @include TBold12;

  display: block;
  flex-grow: 1;
  padding: 12px 0;
  color: $Gray43;
  text-align: left;
  justify-content: stretch;

  &::placeholder {
    color: $Gray46;
    font-weight: 500;
  }
}