@import "style/animations.scss";
@import "style/colors.scss";
@import "style/typo-mixins.scss";

.alert__window {
  width: 100%;
  max-width: 270px;
  margin: auto;
  padding: 16px 0 0;
  border-radius: 14px;
  background: $White-40-84;
  backdrop-filter: blur(11px);
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $Black-30;
  transition: backdrop-filter 0.3s, background-color 0.3s;
  transition-timing-function: ease-out;
  backdrop-filter: blur(5px);
}

.show {
  animation: 0.3s ease-out forwards appearence;
}

.hide {
  animation: 0.3s ease-out forwards hiding;
}

.title,
.text {
  padding: 0 16px;
  color: $White;
  text-align: center;
}

.title {
  @include THeavy12;

  margin-bottom: 8px;
  color: $Gray43;
}

.text {
  @include TBold10;

  margin-bottom: 24px;
  color: $Gray46;
}

.buttonsContainer {
  display: flex;
  border-top: 0.5px solid $Gray46;
}

.button {
  @include TBold10;

  display: block;
  width: 100%;
  padding: 11px 0;
  text-align: center;
}

.submit {
  border-right: 0.5px solid $Gray46;
  color: $Red;
}

.cancel {
  color: $Blue;
}
