@import "style/colors";
@import "style/typo-mixins";
@import "style/utils";

.root {
  padding: 5px 16px 5px 5px;
}

.photo {
  object-fit: contain;
  overflow: hidden;
  width: 50px;
  min-width: 50px;
  height: 50px;
  padding: 8px;
  background-color: $Gray48;
}

.cartContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  margin-left: auto;
}

.veshalka {
  width: 16px;
}

.count {
  @include TBold10;
  @include FlexCentered;

  color: $Gray43;
  transform: translateX(1px);
}
