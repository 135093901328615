@import "style/colors";
@import "style/typo-mixins";

.root {
  @include TBold12;

  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.disabled {
    color: $Gray42;
    cursor: default;
  }
}

.realInput {
  position: absolute;
  top: 0;
  left: -100px;
}

.checkbox {
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border: 1px solid $Gray42;

  &.checked {
    border-color: $Gray43;
    background: $Gray43 url("/static/img/icons-v6/checkmark-white.svg") no-repeat center;
  }
}

.text {}